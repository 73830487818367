<template>
  <div class="eb-product-details-page">
    <div class="row margin-0">
      <div class="col-12 pad-0">
        <h3>Offers &amp; Deals</h3>
      </div>
      <div class="col-8 pad-0">
        <template v-for="(deal, idx) in deals">
          <DealCard :key="idx" :deal="deal" />
        </template>
      </div>
      <div class="col-4 pad-0">
        <my-ad adtype="DHRF" />
      </div>
      <!-- <div class="col-12 pad-0">
        <ul class="offers-menu">
          <li @click="tab='All'" :class="tab==='All'?'active':''">All Deals</li>
          <li @click="tab='Coupon'" :class="tab==='Coupon'?'active':''">Coupons</li>
          <li @click="tab='Amazon'" :class="tab==='Amazon'?'active':''">Amazon Deals</li>
        </ul>
      </div>
      <div class="col-12 pad-0">
        <div class="row margin-0">
          <div class="col-12 pad-10">
            <sn-input 
              type="search"
              :show-label="false"
              label="Search Product Name, Brand, Category etc"
              name="Search Product Name, Brand, Category etc"
              placeholder="Search Product Name, Brand, Category etc"
              v-model="search_text"
              :oerror="error"
              @enter="searchProducts()"
              :value="search_text"
            />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import DealCard from './components/DealCard.vue';
export default {
  components: {
    DealCard
  },
  props: ["id"],
  data() {
    return {
      deals: [
        {
          title: 'Amazon Prime',
          description: 'Amazon Prime',
        },
        {
          title: 'Amazon Prime',
          description: 'Amazon Prime',
        },
        {
          title: 'Amazon Prime',
          description: 'Amazon Prime',
        }
      ],
      tab: 'All',
      search_text: null,
      error: null
    };
  },
  computed: {
    windowRef: function() {
      if(window) {
        return window;
      }
      return null;
    }
  },
  watch: {
    profile: function () {
      if (this.profile && this.action) {
        this.openLink(this.action);
      }
    },
  },
  methods: {
    searchProducts() {
      const endpoint = 'https://webservices.amazon.com/paapi5/searchitems';
      const params = {
        'Keywords': this.search_text,
        'PartnerType': 'Associates', // Specify that you're an Amazon Associate
        // Add other required parameters
      };

      // Make the API request
      axios.get(endpoint, {
        params,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer AKIAJNWD7SYOZWFC3P6Q`
        }
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        // Handle errors
      });
    }
  },
};
</script>
<style lang="stylus" scoped>
.eb-product-details-page {
  padding: 0;
  min-height: 92vh;
  // background-color: #fff;

  h5 {
    margin: 0 0 16px;
    font-weight: 700;
    font-size: 20px;
    color: #002f34;
  }
}
.offers-menu {
  list-style: none;
  display: flow-root;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
  li {
    font-weight: 600;
    padding: 10px;
    color: #888;
    float: left;
    cursor: pointer;
    &.active {
      color: #000
    }
  }
}
</style>
