<template>
  <div class="deal-card">
    <div class="deal-header">
      <h2 class="deal-title">{{ deal.title }}</h2>
      <span class="deal-code">{{ deal.code }}</span>
    </div>
    <div class="deal-description">
      <p>{{ deal.description }}</p>
    </div>
    <div class="deal-footer">
      <span class="deal-expiry">Expires on: {{ deal.expiryDate }}</span>
      <button class="deal-button" @click="redeemDeal">Redeem</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  methods: {
    redeemDeal() {
      // For example: Show a success message, mark the deal as redeemed, etc.
      console.log(`Deal ${this.deal.title} redeemed!`);
    },
  },
};
</script>

<style scoped>
.deal-card {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.deal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deal-title {
  margin: 0;
}

.deal-code {
  font-weight: bold;
}

.deal-description {
  margin-top: 10px;
}

.deal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.deal-expiry {
  color: #888;
}

.deal-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
